import { DateTime } from 'luxon';
import { HEARTBEAT_WARNING_LIMIT } from 'src/config';
import IAlert from 'src/ServerEntities/IAlert';
import IEnsembleInterface from 'src/ServerEntities/IEnsembleInterface';
import IServer from 'src/ServerEntities/IServer';
import NotesContainer from 'src/Views/Ram/Components/NotesContainer';
import ServerAlertIcon from 'src/Views/Ram/Components/ServerAlertIcon';
import ServerConsoleErrors from 'src/Views/Ram/Components/ServerConsoleErrors';
import ServerHeartbeatIcon from 'src/Views/Ram/Components/ServerHeartbeatIcon';
import ServerHistory from 'src/Views/Ram/Components/ServerHistory';
import ServerInformation from 'src/Views/Ram/Components/ServerInformation';
import ServerInterfaces from 'src/Views/Ram/Components/ServerInterfaces';
import ServerMessageThroughput from 'src/Views/Ram/Components/ServerMessageThroughput';
import ServerProcesses from 'src/Views/Ram/Components/ServerProcesses';
import TypeHolder from 'src/Views/Ram/Components/TypeHolder';
import styled from 'styled-components';
import AlertItem from './AlertItem';
import MirrorMetrics from './Components/MirrorMetrics';
import ServerLicenceInformation from './Components/ServerLicenceInformation';
import EnsembleSummaryView from './Components/EnsembleSummaryView';
import EnsemblePinnedInterfaces from './Components/EnsemblePinnedInterfaces';
import { useState } from 'react';
import useCookie from 'src/CustomHooks/useCookie';
import IEnsembleCommPoint from 'src/ServerEntities/IEnsembleCommPoint';
import IQveraInformation from 'src/ServerEntities/IQveraInformation';
import QveraSummaryView from './Components/QveraSummaryView';


const AlertCount = styled.i`
    font-style: normal;
    padding: 4px 12px;
`;

const ServerContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    @media (min-width: 1279px) {
        padding: 24px;
    }
`;

const Title = styled.h2`
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0 0 4px;
    @media (min-width: 1279px) {
        margin: 0 0 16px;
    }
`;

const SiteRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
`;

const ServerItem = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    @media (max-width: 1279px) {
        width: 100%;
    }
`;

const ServerDetailsRow = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 1279px) {
        flex-direction: column;
    }
    & > div {
        margin: 12px 12px;
    }
`;

const ServerHeader = styled.div`
    background: #1c3e5a;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 4px;
    margin-bottom: 4px;
    & p {
        margin: 0;
    }
    flex-wrap: wrap;
    @media (min-width: 1279px) {
        margin-bottom: 16px;
        padding: 0 12px;
        border-radius: 16px;
    }
`;

const SiteLabel = styled.p`
    margin: 0 0 0 10px;
`;

const ItemHeader = styled.header`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0 0 auto;
    padding: 2px 12px;
    border-radius: 16px;
    margin: 0 8px;
    &.with-alerts {
        background-color: #f99600;
    }
    &.without-heartbeat {
        background-color: #ff2121;
    }
`;

const ServerTitle = styled.h3`
    font-weight: 500;
    width: 200px;
    flex: 0 0 auto;
    margin: 0;
`;

const AlertsContainer = styled.div`
    margin-top: 6px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    & p {
        margin: 0;
    }
    @media (min-width: 1279px) {
        margin-top: 24px;
    }
`;

const EnsembleInterfaceRow = styled(ServerDetailsRow)`
    display: grid;
    grid-template-columns: 50% 50%;
`

interface IProps {
    closedAlerts: IAlert[],
    currentAlerts: IAlert[],
    ensembleInterfaces: IEnsembleInterface[],
    ensembleCommPoints: IEnsembleCommPoint[],
    qveraInformation: IQveraInformation[],
    notesOpen: boolean,
    refresh: number,
    server: IServer | undefined,
    setNotesOpen: (open: boolean) => void,
    setRefresh: (refresh: number) => void,
    siteName: string,
    siteType: string,
    webToken: string,
};

const alertToItem = (siteName: string, refresh: number, server: string, setRefresh: (count: number) => void) => (alert: IAlert, index: number) => {
    return <AlertItem
        alert={alert}
        closed={false}
        key={`site-${siteName}-alert-${index}`}
        setRefresh={setRefresh}
        server={server}
        refresh={refresh}
        showCurrentIssues={false}
        size="medium"
    />;
};

const ServerDetails = (props: IProps) => {
    const { closedAlerts, currentAlerts, ensembleInterfaces, ensembleCommPoints, qveraInformation, notesOpen, refresh, server, setNotesOpen, setRefresh, siteName, siteType, webToken } = props;
    const [pinnedInterfaces, setPinnedInterfaces] = useState<IEnsembleInterface[]>([])
    const [pinnedCommPoints, setPinnedCommPoints] = useState<IEnsembleCommPoint[]>([])
    const [interfaceCookies, setInterfaceCookies] = useCookie<IEnsembleInterface[]>('pinnedInterfaces', [] as unknown as IEnsembleInterface[]);
    const [commPointCookies, setCommPointCookies] = useCookie<IEnsembleCommPoint[]>('pinnedCommPoints', [] as unknown as IEnsembleCommPoint[]);

    if (interfaceCookies.length > 0 && pinnedInterfaces.length === 0) {
        setPinnedInterfaces(interfaceCookies);
    }

    if (commPointCookies.length > 0 && pinnedCommPoints.length === 0) {
        setPinnedCommPoints(commPointCookies);
    }

    if (!server) {
        return <ServerContainer>
            <p>No matching server found</p>
        </ServerContainer>;
    };

    const heartbeatHealthy = server.lastHeartbeat > (DateTime.now().toMillis() - HEARTBEAT_WARNING_LIMIT);

    return <ServerContainer>
        <Title>{server.type} server</Title>
        <SiteRow>
            <TypeHolder className={`site-type-${siteType.toLocaleLowerCase()}`}>{siteType}</TypeHolder>
            <ServerItem>
                <ServerHeader>
                    <SiteLabel>{siteName}</SiteLabel>
                    <ItemHeader className={`${server.alerts > 0 ? "with-alerts " : ""}${heartbeatHealthy ? "" : "without-heartbeat"}`}>
                        <ServerTitle>{server.name}</ServerTitle>
                        <ServerAlertIcon server={server} />
                        <ServerHeartbeatIcon server={server} heartbeatHealthy={heartbeatHealthy} />
                        <AlertCount>{server.alerts}</AlertCount>
                    </ItemHeader>
                    <p>{server.namespace}</p>
                    <p>{server.production}</p>
                </ServerHeader>
                {server.messageThroughput && server.messageThroughput.length > 0 && <ServerDetailsRow>
                    <ServerMessageThroughput messageThroughput={server.messageThroughput} />
                </ServerDetailsRow>}
                <ServerDetailsRow>
                    <ServerHistory server={server} />
                    <ServerInformation server={server} startExpanded={false} />
                    {server.interfaceChanges && server.interfaceChanges.length > 0 &&
                        <ServerInterfaces interfaceChanges={server.interfaceChanges} />
                    }
                    {server.runningProcesses && server.runningProcesses.length > 0 &&
                        <ServerProcesses startExpanded={false} processes={server.runningProcesses} />
                    }
                    {server.consoleErrors &&
                        <ServerConsoleErrors startExpanded={false} consoleErrors={server.consoleErrors} />
                    }
                    {server.nodes && server.nodes.length > 0 &&
                        <MirrorMetrics nodes={server.nodes} />
                    }
                    {server.licences && server.licences.length > 0 && <ServerLicenceInformation licenceInformation={server.licences} />}
                </ServerDetailsRow>
                <EnsembleInterfaceRow>
                    {qveraInformation && qveraInformation.length > 0 &&
                        <div className="summary-view">
                            <QveraSummaryView qveraInformation={qveraInformation} />
                        </div>
                    }
                </EnsembleInterfaceRow>
                <EnsembleInterfaceRow>
                    {ensembleInterfaces && ensembleInterfaces.length > 0 &&
                        <div className="summary-view">
                            <EnsembleSummaryView ensembleInterface={ensembleInterfaces} setPinnedInterfaces={setPinnedInterfaces} pinnedInterfaces={pinnedInterfaces} setInterfaceCookies={setInterfaceCookies} />
                        </div>
                    }
                    {pinnedInterfaces && pinnedInterfaces.length > 0 &&
                        <div className="pinned-interfaces">
                            <EnsemblePinnedInterfaces ensembleInterface={pinnedInterfaces} setPinnedInterfaces={setPinnedInterfaces} setInterfaceCookies={setInterfaceCookies} />
                        </div>
                    }
                </EnsembleInterfaceRow>

                <EnsembleInterfaceRow>
                    {ensembleCommPoints && ensembleCommPoints.length > 0 &&
                        <div className="summary-view">
                            <EnsembleSummaryView ensembleCommPoints={ensembleCommPoints} setPinnedCommPoints={setPinnedCommPoints} pinnedCommPoints={pinnedCommPoints} setCommPointCookies={setCommPointCookies} />
                        </div>
                    }
                    {pinnedCommPoints && pinnedCommPoints.length > 0 &&
                        <div className="pinned-interfaces">
                            <EnsemblePinnedInterfaces ensembleCommPoints={pinnedCommPoints} setPinnedCommPoints={setPinnedCommPoints} setCommPointCookies={setCommPointCookies} />
                        </div>
                    }
                </EnsembleInterfaceRow>

                <NotesContainer
                    namespace={server.namespace}
                    notes={server.notes}
                    serverName={server.name}
                    siteName={siteName}
                    notesOpen={notesOpen}
                    setNotesOpen={setNotesOpen}
                    webToken={webToken} />
                <AlertsContainer>
                    <Title>Current issues</Title>
                    {currentAlerts.length === 0 && <p>No current issues.</p>}
                    <ul>
                        {currentAlerts.map(alertToItem(siteName, refresh, server.name, setRefresh))}
                    </ul>
                </AlertsContainer>
                <AlertsContainer>
                    <Title>Closed issues</Title>
                    {closedAlerts.length === 0 && <p>No recent closed issues.</p>}
                    <ul>
                        {closedAlerts.map(alertToItem(siteName, refresh, server.name, setRefresh))}
                    </ul>
                </AlertsContainer>
            </ServerItem>
        </SiteRow>
    </ServerContainer>
};

export default ServerDetails;
