import * as React from "react";
import { useParams } from "react-router";
import useEffectOnSome from "src/CustomHooks/useEffectOnSome";

import ErrorBox from "src/SharedComponents/ErrorBox";
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';

import { RolesContext } from 'src/Roles/RolesContext';
import { getPreferredRole, getRamConfigurationForRole, getRoles } from 'src/Roles/RolesService';
import { SessionContext } from 'src/Views/SessionContext';

import IRoleAndJwt from "src/Roles/IRoleAndJwt";
import { IRamConfiguration } from 'src/ServerEntities/IConfiguration';
import RamHome from "./RamHome";
import PreferredRolePicker from "../PreferredRolePicker";

interface IProps {
    pageName?: string
};

const RamIndex = (props: IProps) => {
    const pageName = useParams<"pageName">().pageName || props.pageName;
    const { dispatch, state } = React.useContext(RolesContext);
    const role = state.role;
    const sessionContext = React.useContext(SessionContext);
    const { webToken } = sessionContext.state;
    const [loading, setLoading] = React.useState(true);
    const [roles, setRoles] = React.useState([] as unknown as string[]);
    const [error, setError] = React.useState("");

    const updateRamRoles = (rolesData: any) => {
        setRoles(rolesData || []);
        setLoading(false);
    };

    const noRoles = () => {
        setLoading(false);
        return <ErrorBox>No roles.</ErrorBox>
    };

    const setNoPreferredRole = () => {
        setLoading(false);
    };

    const handleConfigurationError = (serverError: string) => {
        setError(serverError);
    };

    const updateRole = (response: IRoleAndJwt) => {
        dispatch({ type: "setRole", payload: response.role });
        getRamConfigurationForRole(response.role, response.jwt, updateConfiguration, handleConfigurationError);
        sessionContext.dispatch({ type: "setWebToken", payload: response.jwt });
    };

    const updateConfiguration = (configuration: IRamConfiguration) => {
        setLoading(false);
        dispatch({ type: "setRamConfiguration", payload: configuration });
    };

    useEffectOnSome(() => {
        if (role) {
            // The role we've used to get here is not a RAM role - probably it's from patient view.
            if (roles.length === 0) {
                getRoles("RAM", webToken, updateRamRoles, noRoles);
            } else {
                getPreferredRole("RAM", webToken, updateRole, setNoPreferredRole);
            }
        } else {
            setLoading(false);
        }
    }, [role, roles.length], [webToken, updateRole]);

    if (error) {
        return <ErrorBox>{error}</ErrorBox>;
    }

    if (loading) {
        return <LoadingIndicator type="Radial" show={true} />
    }

    if (role && state.ramConfiguration) {
        return <RamHome pageName={pageName || "Overview"} />;
    } else {
        return <PreferredRolePicker applicationName="RAM" />
    }
};

export default RamIndex;
