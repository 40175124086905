import { DateTime } from 'luxon';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import Timeago from 'react-timeago';
import useFitText from 'use-fit-text';

import newWindowIcon from 'src/images/customIcons/new-window.png';
import { HEARTBEAT_WARNING_LIMIT } from 'src/config';
import styled from 'styled-components';
import IDisk from 'src/ServerEntities/IDisk';
import IServer from 'src/ServerEntities/IServer';
import handleKeyboardSelect from 'src/UsefulFunctions/handleKeyboardSelect';
import ServerAlertIcon from 'src/Views/Ram/Components/ServerAlertIcon';
import { SessionContext } from 'src/Views/SessionContext';
import ServerHeartbeatIcon from './Components/ServerHeartbeatIcon';
import { silenceServer } from './ramService';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import SilenceServerIcon from './Components/SilenceServerIcon';
import ISitePermissions from 'src/ServerEntities/ISitePermissions';

const Item = styled.li`
    flex: 0 0 auto;
    margin: 4px 0;
    @media (min-width: 1279px) {
        margin: 5px 4px;
        border-radius: 16px;
        max-width: 400px;
    }
    display: flex;
    flex-direction: column;
    @media (max-width: 1279px) {
        width: 100%;
    }
`;

const ItemHeader = styled.header`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0 0 auto;
    cursor: pointer;
    padding: 4px 12px;
    @media (max-width: 1279px) {
        justify-content: space-between;
    }
`;

const HeaderGroup = styled.div`
    display: flex;
    flex-direction: column;
`;

const ServerTitle = styled.h3`
    font-weight: 500;
    width: 200px;
    flex: 0 1 auto;
    margin: 0;
`;

const ServerIcon = styled.img`
    height: 24px;
    width: 24px;
    margin: 0 4px;
`;

const AlertCount = styled.i`
    font-style: normal;
    margin: 0px 12px 0px 0;
    overflow: hidden;
    width: 100px;
    text-overflow: ellipsis;
    text-align: right;
`;

const ServerDetails = styled.dl`
    padding: 4px 12px;
    margin: 0;
    background-color: #1c3e5a;
    display: flex;
    flex-flow: row wrap;
    font-size: 0.8rem;
    flex: 0 0 auto;
    @media (min-width: 1279px) {
        border-radius: 0 0 16px 16px;
    }
`;

const ServerDetailsTerm = styled.dt`
    flex: 0 0 60%;
    margin: 0;
    padding: 0;
`;

const ServerDetailsDefinition = styled.dd`
    flex: 0 0 40%;
    margin: 0;
    padding: 0;
`;

interface IProps {
    displaySite: boolean,
    enlarge: boolean,
    linkToServer: boolean,
    server: IServer,
    site: string,
    siteName: string
    startExpanded?: boolean,
    refresh: number,
    setRefresh: (count: number) => void,
    onExpand?: (id: string) => void,
    sitePermissions: ISitePermissions
};

const toDp = (value: string) => {
    const number = parseFloat(value);
    if (isNaN(number)) {
        return value;
    }
    return new Intl.NumberFormat("en-GB", { maximumFractionDigits: 2 }).format(number);
};

const formatDiskFreeSpace = (value: string) => {
    if (!value.includes("%")) {
        return value + "%";
    }
    return value;
};

const getDiskSpaceComponents = (serverName: string) => (disk: IDisk) => {
    const { freeGb, percentFree, sizeGb, usedGb } = disk;
    const diskId = disk.diskId.replace(":", "");
    const items = [<ServerDetailsTerm style={{ marginTop: "10px" }} key={`server-${serverName}-disk-${diskId}-id`}>Disk {diskId}:</ServerDetailsTerm>];
    if (sizeGb) {
        items.push(<ServerDetailsDefinition style={{ marginTop: "10px" }} key={`server-${serverName}-disk-${diskId}-size`}>Size - {toDp(sizeGb)}GB</ServerDetailsDefinition>);
        items.push(<ServerDetailsTerm key={`server-${serverName}-disk-${diskId}-id-used-key`} />);
    }
    if (usedGb) {
        items.push(<ServerDetailsDefinition style={{ marginTop: sizeGb ? "0" : "10px" }} key={`server-${serverName}-disk-${diskId}-used`}>Used - {toDp(usedGb)}GB</ServerDetailsDefinition>);
        items.push(<ServerDetailsTerm key={`server-${serverName}-disk-${diskId}-id-free-key`} />);
    }
    if (freeGb) {
        items.push(<ServerDetailsDefinition key={`server-${serverName}-disk-${diskId}-free`}>Free - {toDp(freeGb)}GB</ServerDetailsDefinition>);
        items.push(<ServerDetailsTerm key={`server-${serverName}-disk-${diskId}-id-percent-free-key`} />);
    }
    if (percentFree) {
        items.push(<ServerDetailsDefinition style={{ marginBottom: "10px" }} key={`server-${serverName}-disk-${diskId}-precent-free`}>% free - {toDp(percentFree)}</ServerDetailsDefinition>);
    }
    return items;
};

const ServerItem = (props: IProps) => {
    const { displaySite, enlarge, linkToServer, server, siteName, site } = props;
    const navigate = useNavigate();
    const permissions = props.sitePermissions;
    const isSmall = useMediaQuery({
        query: '(max-width: 1279px)'
    });
    const { state } = React.useContext(SessionContext);
    const [expanded, setExpanded] = React.useState(props.startExpanded);
    const { fontSize: siteFontSize, ref: siteRef } = useFitText();
    const { fontSize: namespaceFontSize, ref: namespaceRef } = useFitText();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    const openServer = (serverName: string) => (event: React.MouseEvent<HTMLImageElement>) => {
        window.open(`/Ram/Server/${site}/${serverName}/${server.namespace}/${state.webToken}`);
        event.stopPropagation();
    };

    const toggleExpanded = () => {
        if (isSmall) {
            navigate(`/Ram/Server/${site}/${server.name}/${server.namespace}/${state.webToken}`);
        } else {
            if (props.onExpand) {
                props.onExpand(server.id);
            }
            setExpanded(!expanded);
        }
    };

    const toggleSilenceServer = (serverName: string) => (event: React.MouseEvent<HTMLImageElement>) => {
        setLoading(true);
        silenceServer(serverName, state.webToken, (response: string) => {
            setLoading(false);
            props.setRefresh(props.refresh + 1)
            setError("");
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }

    const sortDisks = (a: IDisk, b: IDisk) => {
        if (b.diskId > a.diskId) {
            return -1;
        }
        return 0;
    }

    const heartbeatHealthy = server.lastHeartbeat > (DateTime.now().toMillis() - HEARTBEAT_WARNING_LIMIT);
    const titleHeight = enlarge && !heartbeatHealthy ? "48px" : "24px";
    return <Item key={`server-item-${server.name}`} className={`${server.alerts > 0 ? "with-alerts " : ""}${heartbeatHealthy ? "" : "without-heartbeat"}`}>
        <ItemHeader onClick={toggleExpanded} onKeyDown={handleKeyboardSelect(toggleExpanded)} tabIndex={0}>
            <HeaderGroup>
                <ServerTitle ref={siteRef} style={{ fontSize: siteFontSize, height: titleHeight }}>
                    {displaySite ? `${siteName} - ${server.name}` : server.name}
                </ServerTitle>
                {server.namespace && <ServerTitle ref={namespaceRef} style={{ fontSize: namespaceFontSize, height: titleHeight }}>{server.namespace}</ServerTitle>}
            </HeaderGroup>
            {linkToServer && !isSmall && <ServerIcon
                alt={`View server ${server.name} in a new tab`}
                onClick={openServer(server.name)}
                src={newWindowIcon} />}
            {permissions && permissions.canSilence && <SilenceServerIcon onClick={toggleSilenceServer(server.id)} />}
            <ServerAlertIcon server={server} />
            <ServerHeartbeatIcon server={server} heartbeatHealthy={heartbeatHealthy} />
            <AlertCount>{server.alerts}</AlertCount>
        </ItemHeader>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
        {expanded && <ServerDetails>
            <ServerDetailsTerm>Last heartbeat:</ServerDetailsTerm><ServerDetailsDefinition><Timeago date={server.lastHeartbeat} /></ServerDetailsDefinition>
            {server.backup && [<ServerDetailsTerm>Last backup:</ServerDetailsTerm>, <ServerDetailsDefinition><Timeago date={server.backup.date} /></ServerDetailsDefinition>]}
            {server.database && [
                <ServerDetailsTerm>Database current size:</ServerDetailsTerm>,
                <ServerDetailsDefinition>{server.database.databaseSize}</ServerDetailsDefinition>,
                <ServerDetailsTerm>Database free space:</ServerDetailsTerm>,
                <ServerDetailsDefinition>{server.database.availableSize} {formatDiskFreeSpace(server.database.databaseFreeSpace)}</ServerDetailsDefinition>
            ]}
            {server.disks && server.disks.sort(sortDisks).map(getDiskSpaceComponents(server.name))}
            {server.uptime && server.uptime.lastOsBoot && [
                <ServerDetailsTerm>Last OS boot:</ServerDetailsTerm>, <ServerDetailsDefinition><Timeago date={server.uptime.lastOsBoot} /></ServerDetailsDefinition>
            ]}
            {server.uptime && server.uptime.lastTieBoot && [
                <ServerDetailsTerm>Last TIE boot:</ServerDetailsTerm>, <ServerDetailsDefinition><Timeago date={server.uptime.lastTieBoot} /></ServerDetailsDefinition>
            ]}
        </ServerDetails>}
    </Item>;
};

export default ServerItem;
