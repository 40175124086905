import * as React from 'react';
import styled from 'styled-components';

import ICombinedSiteSupportIssues from 'src/ServerEntities/ICombinedSiteSupportIssues';
import ISiteSupportIssues from 'src/ServerEntities/ISiteSupportIssues';
import ISupportIssue from 'src/ServerEntities/ISupportIssue';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import renderSiteName from 'src/UsefulFunctions/renderSiteName';
import SupportIssueItem from 'src/Views/Ram/Components/SupportIssueItem';
import TypeHolder from 'src/Views/Ram/Components/TypeHolder';
import { SessionContext } from 'src/Views/SessionContext';
import { getSupportIssues, saveSupportIssue } from './ramService';
import HeaderLink from './Components/HeaderLink';
import ISupportIssueRequest from 'src/ServerEntities/ISupportIssueRequest';
import CreateSupportIssueDialog from './Components/CreateSupportIssueDialog';
import { useNavigate } from 'react-router';
import { RolesContext } from 'src/Roles/RolesContext';
import { getFirstPathOn401RoleChange } from 'src/Roles/RolesService';

const ContentContainer = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    @media (min-width: 1279px) {
        padding: 48px 48px 84px;
    }
    @media (max-width: 1279px) {
        margin-bottom: 80px;
    }
`;

const ServerList = styled.ul`
    margin: 0 0 24px;
`;

const ListTitle = styled.h2`
    font-size: 1.4rem;
    font-weight: 600;
`;

const EmptyText = styled.p`
    margin: 0;
`;

const AlertHeader = styled.header`
    display: flex;
    flex-direction: row;
`;

const ClosedIssues = styled.div`
    @media (max-width: 1279px) {
        display: none;
    }
`;

const AlertTitle = styled.div`
    background-color: #1c3e5a;
    &.closed {
        background-color: #6d2f35;
    }
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 4px;
    flex-wrap: wrap;
    & h2 {
        margin: 0;
        font-weight: 500;
        font-size: 1.2rem;
        @media (max-width: 1279px) {
            margin: 0 4px;
        }
    }
    @media (min-width: 1279px) {
        border-radius: 16px;
        padding: 0 24px;
        flex: 1 1 auto;
    }
`;

const AlertList = styled.ul`
    margin: 1px 0 12px;
    @media (min-width: 1279px) {
        margin: 12px 0 0 96px;
    }
    padding: 0;
    list-style-type: none;
`;

interface IProps {
    selectedTypes: string[],
    viewUserIssues: boolean,
    changeRole: boolean,
    setChangeRole: (changeRole: boolean) => void
};

const supportIssueToItem = (server: string, closed: boolean, refresh: number, setRefresh: (count: number) => void) =>
    (issue: ISupportIssue, index: number) => {
        return <SupportIssueItem
            key={`support-issues-view-issue-${index}`}
            issue={issue}
            server={server}
            closed={closed}
            refresh={refresh}
            setRefresh={setRefresh}
            showCurrentIssues={true}
            size="large"
        />;
    };

const serverToItem = (closed: boolean, refresh: number, setRefresh: (count: number) => void) =>
    (site: ISiteSupportIssues, index: number) => {
        return <li key={`site-${site.site}-issues-${index}`}>
            <AlertHeader>
                <TypeHolder className={`site-type-${site.type.toLocaleLowerCase()}`}>{site.type}</TypeHolder>
                <AlertTitle className={closed ? "closed" : "current"}>
                    <h2>{renderSiteName(site.site, site.alias)}</h2>
                </AlertTitle>
            </AlertHeader>
            <AlertList>
                {site.supportIssues.sort((issueA: ISupportIssue, issueB: ISupportIssue) => issueB.date - issueA.date)
                    .map(supportIssueToItem(site.site, closed, refresh, setRefresh))}
            </AlertList>
        </li>;
    };

const SupportIssues = (props: IProps) => {
    const { selectedTypes, viewUserIssues, changeRole, setChangeRole } = props;
    const { canRaiseSupport, loggedInUser, webToken } = React.useContext(SessionContext).state;
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [currentIssues, setCurrentIssues] = React.useState([] as unknown as ISiteSupportIssues[]);
    const [closedIssues, setClosedIssues] = React.useState([] as unknown as ISiteSupportIssues[]);
    const [refresh, setRefresh] = React.useState(0);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const roleContext = React.useContext(RolesContext);
    const navigate = useNavigate();

    const checkRoleChange = async (errorMessage: string) => {
        if(changeRole) {
            const path = await getFirstPathOn401RoleChange(errorMessage, webToken, roleContext.state.ramConfiguration?.components, roleContext.state.role)
            if(path) {
                navigate(path);
            }
            setChangeRole(false);
        } 
    }

    React.useEffect(() => {
        setLoading(true);
        getSupportIssues(webToken, (serverIssues: ICombinedSiteSupportIssues) => {
            setLoading(false);
            setCurrentIssues(serverIssues.currentSiteIssues);
            setClosedIssues(serverIssues.closedSiteIssues);
            setError("");
            setChangeRole(false);
        }, (errorMessage: string) => {
            checkRoleChange(errorMessage);   
            setLoading(false);
            setError(errorMessage);
        });
    }, [webToken, refresh]);

    const displayCreateIssueDialog = () => {
        setDialogOpen(true);
    };

    const closeCreateIssueDialog = () => {
        setRefresh(refresh + 1);
        setLoading(false);
        setDialogOpen(false);
    };

    const onSaveIssue = (issue: ISupportIssueRequest) => {
        if (issue.issue) {
            setLoading(true);
            saveSupportIssue(issue, webToken, closeCreateIssueDialog, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    };

    const filteredCurrentIssues = currentIssues.filter(siteIssues => selectedTypes.length === 0 || selectedTypes.includes(siteIssues.type));
    const filteredClosedIssues = closedIssues.filter(siteIssues => selectedTypes.length === 0 || selectedTypes.includes(siteIssues.type));

    const currentUserIssues = currentIssues.map((issue) => {
        const filteredIssues = issue.supportIssues.filter(issue => issue.username === loggedInUser && issue.username !== null);
        return {...issue, supportIssues: filteredIssues}
    })

    const closedUserIssues = closedIssues.map((issue) => {
        const filteredIssues = issue.supportIssues.filter(issue => issue.username === loggedInUser && issue.username !== null);
        return {...issue, supportIssues: filteredIssues}
    })

    return <ContentContainer>
        {dialogOpen && <CreateSupportIssueDialog error={error} loading={loading} onClose={closeCreateIssueDialog} onSave={onSaveIssue} />}
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
        {canRaiseSupport &&
            <div>
                <HeaderLink onClick={displayCreateIssueDialog}>Raise new support issue</HeaderLink>
            </div>
        }
        <ListTitle>Current support tickets</ListTitle>
        {filteredCurrentIssues.length === 0 && <EmptyText>No current issues</EmptyText>}
        <ServerList>
            {viewUserIssues ? currentUserIssues.map(serverToItem(false, refresh, setRefresh)) : filteredCurrentIssues.map(serverToItem(false, refresh, setRefresh))}
        </ServerList>
        <ClosedIssues>
            <ListTitle>Closed issues</ListTitle>
            {filteredClosedIssues.length === 0 && <EmptyText>No closed issues</EmptyText>}
            <ServerList>
                {viewUserIssues ? closedUserIssues.map(serverToItem(false, refresh, setRefresh)) : filteredClosedIssues.map(serverToItem(true, refresh, setRefresh))}
            </ServerList>
        </ClosedIssues>
    </ContentContainer>;
};

export default SupportIssues;
