import { DateTime } from 'luxon';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import 'react-toggle/style.css';
import styled from 'styled-components';

import { HEARTBEAT_WARNING_LIMIT } from 'src/config';
import alertBadIcon from 'src/images/customIcons/alert-bad.png';
import alertGoodIcon from 'src/images/customIcons/alert-good.png';
import heartbeatBadIcon from 'src/images/customIcons/heartbeat-bad.png';
import heartbeatGoodIcon from 'src/images/customIcons/heartbeat-good.png';
import newWindowIcon from 'src/images/customIcons/new-window.png';
import ISite from 'src/ServerEntities/ISite';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import dateConverter from 'src/UsefulFunctions/dateConverter';
import renderSiteName from 'src/UsefulFunctions/renderSiteName';
import { SessionContext } from 'src/Views/SessionContext';
import { getOverview } from './ramService';
import { getFirstPathOn401RoleChange } from 'src/Roles/RolesService';
import { RolesContext } from 'src/Roles/RolesContext';

const SitesTitle = styled.h2`
    font-weight: 600;
    font-size: 1.4rem;
    margin: 0;
`;

const ItemHeader = styled.div`
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (min-width: 1279px) {
        width: 300px;
        margin-right: 24px;
    }
`;

const SiteContainer = styled.ul`
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
`;

const SiteItem = styled.li`
    border-radius: 16px;
    flex: 0 0 auto;
    margin: 5px 4px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 560px;
    background-color: #42a83f;
    order: 2;
    @media (max-width: 1279px) {
        margin: 6px 0;
        padding: 6px;
        display: none;
        border-radius: 0;
        flex: 1 1 auto;
        width: 100%;
        cursor: pointer;
    }
    &.with-alerts {
        background-color: #f99600;
        order: 1;
        @media (max-width: 1279px) {
            display: flex;
        }
    }
    &.without-heartbeat {
        order: 0;
        font-size: 2rem;
        & h3 {
            @media (min-width: 1279px) {
                width: 300px;
            }
        }
        background-color: #ff2121;
        @media (max-width: 1279px) {
            display: flex;
        }
    }
`;

const Title = styled.h1`
    font-size: 2rem;
    margin: 4px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (min-width: 1279px) {
        width: 300px;
        font-size: 3rem;
    }
`;

const SubTitle = styled.h3`
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
`;

const ContentContainer = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    @media (min-width: 1279px) {
        padding: 48px 48px 84px;
    }
    @media (max-width: 1279px) {
        margin-bottom: 80px;
    }
`;

const LinkIcon = styled.img`
    cursor: pointer;
    height: 48px;
    width: 48px;
    margin: 0 4px;
    @media (max-width: 1279px) {
        display: none;
    }
`;

const SiteIcon = styled.img`
    height: 48px;
    width: 48px;
    margin: 0 4px;
    @media (max-width: 1279px) {
        display: none;
    }
`;

const AlertCount = styled.i`
    font-style: normal;
    margin: 0 12px;
    font-size: 1.rem;
    min-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 1279px) {
        display: none;
    }
`;


const siteToRow = (openSite: (site: string) => (event: React.MouseEvent<HTMLElement>) => void, isSmall: boolean) => (site: ISite, index: number) => {
    const oldestHeartbeat = Math.min(...site.servers.map(server => server.lastHeartbeat));
    const heartbeatHealthy = oldestHeartbeat > (DateTime.now().toMillis() - HEARTBEAT_WARNING_LIMIT);
    const siteName = renderSiteName(site.site, site.alias);

    const openSiteIfSmall = (event: React.MouseEvent<HTMLElement>) => {
        if (isSmall) {
            openSite(site.site)(event);
        }
    };

    return <SiteItem onClick={openSiteIfSmall} className={`${site.alerts > 0 ? "with-alerts " : ""}${heartbeatHealthy ? "" : "without-heartbeat"}`}
        key={`dashboard-site-${site.site}-${index}`}>
        <ItemHeader>
            <Title>{siteName}</Title>
            <SubTitle>Servers: {site.servers.length}</SubTitle>
        </ItemHeader>
        <LinkIcon
            alt={`View ${siteName} details`}
            onClick={openSite(site.site)}
            src={newWindowIcon} />
        <SiteIcon
            alt={`${site.alerts} alerts for ${siteName}`}
            src={site.alerts === 0 ? alertGoodIcon : alertBadIcon} />
        <SiteIcon
            alt={`Oldest server heartbeat received from ${siteName} at ${dateConverter(oldestHeartbeat)}`}
            src={heartbeatHealthy ? heartbeatGoodIcon : heartbeatBadIcon} />
        <AlertCount>{site.alerts}</AlertCount>
    </SiteItem>;
};

interface IProps {
    selectedTypes: string[],
    changeRole: boolean,
    setChangeRole: (changeRole: boolean) => void
};

const SiteOverview = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { selectedTypes, changeRole, setChangeRole } = props;
    const [sites, setSites] = React.useState([] as unknown as ISite[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const roleContext = React.useContext(RolesContext);
    const navigate = useNavigate();

    const isSmall = useMediaQuery({
        query: '(max-width: 1279px)'
    });

    const openSite = (site: string) => (event: React.MouseEvent<HTMLElement>) => {
        navigate(`/Ram/Sites/${site}`);
        event.stopPropagation();
    };

    const checkRoleChange = async (errorMessage: string) => {
        if(changeRole) {
            const path = await getFirstPathOn401RoleChange(errorMessage, state.webToken, roleContext.state.ramConfiguration?.components, roleContext.state.role)
            if(path) {
                navigate(path);
            }
            setChangeRole(false);
        } 
    }

    React.useEffect(() => {
        setLoading(true);
        getOverview(state.webToken, (serverSites: ISite[]) => {
            setLoading(false);
            setSites(serverSites);
            setError("");
            setChangeRole(false);
        }, (errorMessage: string) => {
            checkRoleChange(errorMessage);
            setLoading(false);
            setError(errorMessage);
        });
    }, [state.webToken]);

    const filteredSites = sites.filter(site => selectedTypes.length === 0 || selectedTypes.includes(site.type));

    return <ContentContainer>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
        <SitesTitle>All sites</SitesTitle>
        <SiteContainer>
            {filteredSites.map(siteToRow(openSite, isSmall))}
        </SiteContainer>
    </ContentContainer>;
};

export default SiteOverview;
