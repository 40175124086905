
import { useState } from "react";
import { useParams } from "react-router";
import IAlert from "src/ServerEntities/IAlert";
import AlertDetail from "./AlertDetail";

const AlertDirectView = () => {
    const { alertId } = useParams<"alertId">();
    const id = alertId ? parseInt(alertId, 10) : 0;
    const [refresh, setRefresh] = useState(0);

    const alert: IAlert = {
        id: id,
        site: "",
        server: "",
        namespace: "",
        production: "",
        service: "",
        type: "",
        alert: "",
        fullAlert: "",
        timestamp: 0,
        hash: "",
        status: "",
        count: 0,
        siteType: "",
        username: "",
        userColour: "#543b64",
        lastUpdate: 0,
        messageId: "",
        ticket: "",
        muted: false
    };

    const refreshData = (count: number) => {
        setRefresh(count);
    };

    return <div>
        {alertId && <AlertDetail
            alert={alert}
            server={""}
            refresh={refresh}
            showCurrentIssues={false}
            onClose={function (): void {}}
            setRefresh={refreshData}
            size={"large"}
        />}
    </div>

};

export default AlertDirectView;
