import { DateTime } from 'luxon';
import useFitText from 'use-fit-text';


import { HEARTBEAT_WARNING_LIMIT } from 'src/config';
import styled from 'styled-components';
import IServer from 'src/ServerEntities/IServer';
import ServerAlertIcon from 'src/Views/Ram/Components/ServerAlertIcon';
import ServerHeartbeatIcon from './Components/ServerHeartbeatIcon';
import { SessionContext } from '../SessionContext';
import React from 'react';
import newWindowIcon from 'src/images/customIcons/new-window.png';

const Item = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    order: 2;
    @media (min-width: 1279px) {
        margin: 5px 4px;
        border-radius: 16px;
        max-width: 400px;
    }
    display: flex;
    flex-direction: column;
    @media (max-width: 1279px) {
        width: 100%;
    }
    &.with-alerts {
        order: 1;
    }
    &.without-heartbeat {
        order: 2;
    }
`;

const ItemHeader = styled.header`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0 0 auto;
    padding: 4px 12px;
    @media (max-width: 1279px) {
        justify-content: space-between;
    }
`;

const HeaderGroup = styled.div`
    display: flex;
    flex-direction: column;
`;

const ServerTitle = styled.h3`
    font-weight: 500;
    width: 170px;
    flex: 0 1 auto;
    margin: 0;
`;

const AlertCount = styled.i`
    font-style: normal;
    margin: 0 12px;
    font-size: 1.rem;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface IProps {
    displaySite: boolean,
    server: IServer,
    siteName: string
};

const ServerIcon = styled.img`
    height: 24px;
    width: 24px;
    margin: 0 4px;
`;

const DashboardServerItem = (props: IProps) => {
    const { displaySite, server, siteName } = props;
    const { fontSize: siteFontSize, ref: siteRef } = useFitText();
    const { fontSize: namespaceFontSize, ref: namespaceRef } = useFitText();
    const { state } = React.useContext(SessionContext);

    const heartbeatHealthy = server.lastHeartbeat > (DateTime.now().toMillis() - HEARTBEAT_WARNING_LIMIT);
    const titleHeight = "24px";

    const openServer = (event: React.MouseEvent<HTMLImageElement>) => {
        window.open(`/Ram/Server/${siteName}/${server.name}/${server.namespace}/${state.webToken}`);
        event.stopPropagation();
    };

    return <Item className={`${server.alerts > 0 ? "with-alerts " : ""}${heartbeatHealthy ? "" : "without-heartbeat"}`}>
        <ItemHeader tabIndex={0} onClick={openServer}>
            <HeaderGroup>
                <ServerTitle ref={siteRef} style={{ fontSize: siteFontSize, height: titleHeight }}>
                    {displaySite ? `${siteName} - ${server.name}` : server.name}
                </ServerTitle>
                {server.namespace && <ServerTitle ref={namespaceRef} style={{ fontSize: namespaceFontSize, height: titleHeight }}>{server.namespace}</ServerTitle>}
            </HeaderGroup>
            <ServerIcon
                alt={`View server ${server.name} in a new tab`}
                onClick={openServer}
                src={newWindowIcon} />
            <ServerAlertIcon server={server} />
            <ServerHeartbeatIcon server={server} heartbeatHealthy={heartbeatHealthy} />
            <AlertCount>{server.alerts}</AlertCount>
        </ItemHeader>
    </Item>;
};

export default DashboardServerItem;
