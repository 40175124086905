import React from 'react';
import IAlert from 'src/ServerEntities/IAlert';
import IServerAlerts from 'src/ServerEntities/IServerAlerts';
import showForCurrentUser from 'src/UsefulFunctions/alertFilter';
import renderSiteName from 'src/UsefulFunctions/renderSiteName';
import AlertList from 'src/Views/Ram/Components/AlertList';
import AlertTitle from 'src/Views/Ram/Components/AlertTitle';
import TypeHolder from 'src/Views/Ram/Components/TypeHolder';
import { SessionContext } from 'src/Views/SessionContext';
import styled from 'styled-components';
import AlertItem from '../AlertItem';

const AlertHeader = styled.header`
    display: flex;
    flex-direction: row;
`;

const ServerListItem = styled.li`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: stretch;
`;

const AlertItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

interface IProps {
    alertListMargin?: string,
    closed: boolean,
    refresh: number,
    server: IServerAlerts,
    setRefresh: (refresh: number) => void,
    viewName: string,
    viewUserIssues: boolean
};

const alertToItem = (siteName: string, refresh: number, server: string, setRefresh: (count: number) => void, viewAll: boolean) => (alert: IAlert, index: number) => {
    return <AlertItem
        alert={alert}
        closed={false}
        key={`site-${siteName}-alert-${index}`}
        setRefresh={setRefresh}
        server={server}
        refresh={refresh}
        showCurrentIssues={false}
        size="medium"
    />;
};

const ServerAlert = (props: IProps) => {
    const { alertListMargin, closed, refresh, server, setRefresh, viewUserIssues, viewName } = props;
    const { loggedInUser } = React.useContext(SessionContext).state;

    return <ServerListItem key={`${viewName}-server-${server.name}-alerts`}>
        <TypeHolder className={`site-type-${server.type.toLocaleLowerCase()}`}>{server.type}</TypeHolder>
        <AlertItemContainer>
            <AlertHeader>
                <AlertTitle className={closed ? "closed" : "current"}>
                    <h2>{renderSiteName(server.site, server.alias)}</h2>
                    <h2>{server.name}</h2>
                    <h2>{server.namespace}</h2>
                    <h2>{server.production}</h2>
                </AlertTitle>
            </AlertHeader>
            <AlertList marginLeft={alertListMargin}>
                {server.alerts.filter(showForCurrentUser(loggedInUser, viewUserIssues))
                    .map(alertToItem(server.name, refresh, server.name, setRefresh, viewUserIssues))}
            </AlertList>
        </AlertItemContainer>
    </ServerListItem>;
};

export default ServerAlert;
