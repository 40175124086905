import React, { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router';
import Timeago from 'react-timeago';
import styled from 'styled-components';

import IAlert from 'src/ServerEntities/IAlert';
import IAlertAction from 'src/ServerEntities/IAlertAction';
import IAlertDetails from 'src/ServerEntities/IAlertDetails';
import ISupportIssueRequest from 'src/ServerEntities/ISupportIssueRequest';
import IUser from 'src/ServerEntities/IUser';

import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import { SessionContext } from 'src/Views/SessionContext';
import AlertIcon from './Components/AlertIcon';
import AlertNotes from './Components/AlertNotes';
import AssignUserDialog from './Components/AssignUserDialog';
import SiteAlertsList from './Components/SiteAlerts';
import UsernameText from './Components/UsernameText';

import { addNote, getAlertDetails, getNotes, saveSupportIssue, setAlertClosed, updateAlertMessageId, updateAlertTicket, updateAlertMuted } from './ramService';

import createErrorMessage from 'src/UsefulFunctions/createErrorMessage';
import dateConverter, { getDifference } from 'src/UsefulFunctions/dateConverter';
import { SIZE } from './AlertItem';
import TextInput from 'src/SharedComponents/TextInput';
import { assignUserToAlert } from '../Manager/UserManagement/roleManagementService';
import CreateSupportIssueDialog from './Components/CreateSupportIssueDialog';

interface IProps {
    alert: IAlert,
    server: string,
    onClose: () => void,
    refresh: number,
    setRefresh: (count: number) => void,
    size: SIZE,
    showCurrentIssues: boolean,
    closed?: boolean
};
interface IHistoryListStyle {
    size: SIZE
};

interface IAlertContainer {
    closed?: boolean
};

interface IPreviousInstanceContainer {
    closed?: boolean
};

interface IAlertHeader {
    closed?: boolean
};

interface IAlertBody {
    colour?: string;
};

const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    margin-top: 8px;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction row;
    flex: 1 0 auto;
    justify-content: space-between;
`;

const DetailContainer = styled.div`
    flex: 0 0 72%;
    display: flex;
    flex-direction: column;
    @media (max-width: 1279px) {
        width: 100%;
        flex: 0 0 auto;
    }
`;

const AlertContainer = styled.article<IAlertContainer>`
    background-color: ${props => props.closed ? "#6D2F35" : "#1c3e5a"};
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    @media (min-width: 1279px) {
        border-radius: 16px;
        margin-bottom: 20px;
    }
    & > div {   
        padding: 4px;
        @media (min-width: 1279px) {
            padding: 12px;
        }
    }
    & h3 {
        margin: 0;
        font-weight: 500;
        font-size: 1.2rem;
    }
    & dt {
        flex: 0 0 30%;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 1279px) {
            flex: 0 0 100%;
        }
    }
    & dd {
        flex: 0 0 calc(70% - 5px);
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-wrap: break-word;
        max-width: 520px;
        @media (max-width: 1279px) {
            flex: 0 0 calc(100% - 10px);
            margin: 0 0 6px 10px;
        }
    }
`;

const PreviousInstanceContainer = styled(AlertContainer)<IPreviousInstanceContainer>`
    background-color: ${props => props.closed ? "#6D2F35" : ""};
    @media (max-width: 1279px) {
        display: none;
    }
`;

const AlertHeader = styled.header<IAlertHeader>`
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: ${props => props.closed ? "#962424" : "#246896"};
    padding: 6px 12px;
    border-radius: 16px 16px 0 0;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1279px) {
        flex-direction: column;
        align-items: flex-start;
        border-radius: 0;
        padding: 4px;
    }
`;

const AlertButtons = styled.div`
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    align-items: center;
    @media (max-width: 1279px) {
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;

const Button = styled.button`
    border-radius: 16px;
    background: #112c42;
    color: #ffffff;
    padding: 8px 12px;
    margin: 0 8px;
    font-size: 1rem;
    cursor: pointer;
    box-shadow: inset 2px 2px 4px rgba(255, 255, 255, 0.1), inset -2px -2px 4px rgba(0, 0, 0, 0.3);

    &:hover {
        background: linear-gradient(145deg, #5a9ec5, #75b8e6);
        box-shadow: inset 3px 3px 6px #4887a8, inset -3px -3px 6px #9cd2ff;
    }

    @media (max-width: 1279px) {
        margin: 3px 0;
    }
`;

const AlertButton = styled.button`
    border-radius: 16px;
    background: #112c42;
    color: #ffffff;
    padding: 8px 16px;
    margin: 2px 0;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    outline: none;
    box-shadow: inset 2px 2px 4px rgba(255, 255, 255, 0.1), inset -2px -2px 4px rgba(0, 0, 0, 0.3);

    &:hover {
        background: linear-gradient(145deg, #5a9ec5, #75b8e6);
        box-shadow: inset 3px 3px 6px #4887a8, inset -3px -3px 6px #9cd2ff;
    }
`;


const AlertBody = styled.div<IAlertBody>`
    flex: 1 1 auto;
    .grid {
        display: grid;
        grid-template-columns: 76% 24%;
    }

    .grid-column-1 {
        grid-coloumn: 1;
        dd, dt {
            margin-top: 12px;
        }
    }

    & h3 {
        margin: 0 0 12px;
        font-size: 1.2rem;
        font-weight: 500;
    }
    @media (max-width: 1279px) {
        width: 100%;
    }
    @media (min-width: 1279px) {
        margin: 0 12px;
    }
`;

const SideContainer = styled.div`
    flex: 0 0 25%;

    @media (max-width: 1279px) {
        display: none;
    }
`;

const CurrentIssues = styled.div`
    display: flex;
    align-items: left;
    justify-content: space-between;
    flex-direction: column;
    & h3 {
        margin: 0 0 6px;
        font-size: 1.1rem;
        font-weight: 500;
    }
`;

const InstanceInformationRow = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
`;

const InstanceInformation = styled.div`
    flex: 0 0 50%;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

const SectionTitle = styled.h4`
    font-size: 1.1rem;
    font-weight: normal;
    margin: 0 0 10px;
`;

const EditableTextContainer = styled.div`
    height: 20px;
`;

const HistoryList = styled.dl<IHistoryListStyle>`
    font-size: ${props => props.size === "large" ? "1rem" : "0.9rem"};
`;

const historyToItem = (alertAction: IAlertAction, index: number) => {
    return [
        <dt key={`alert-history-${index}-key`}>{alertAction.action}</dt>,
        <dd key={`alert-history-${index}-value`}>{dateConverter(alertAction.date, true)}</dd>
    ];
};


const ageFormatter = (value: number, unit: string, suffix: string) => {
    return `${value} ${unit}${value !== 1 ? 's' : ''}`;
};

const getUsername = (alert: IAlert, minimised: boolean, closed: boolean) => {
    if (minimised) {
        return alert.username ? alert.username.substring(0, 3) : "UN";
    }
    if (closed) {
        return `Closed - ${alert.username}`;
    }
    return alert.username || "Unassigned";
};

const AlertDetail = (props: IProps) => {
    const { alert, onClose, refresh, setRefresh, size } = props;
    const { state } = React.useContext(SessionContext);
    const [alertDetails, setAlertDetails] = React.useState([] as unknown as IAlertDetails);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [userAssignment, setUserAssignment] = React.useState(false);
    const [messageId, setMessageId] = React.useState(alert.messageId);
    const [editingMessageId, setEditingMessageId] = React.useState(false);
    const [ticket, setTicket] = React.useState(alert.ticket);
    const [editingTicket, setEditingTicket] = React.useState(false);
    const [showSupportDialog, setShowSupportDialog] = React.useState(false);
    const [issue, setIssue] = React.useState("");
    const navigate = useNavigate();

    const markAsClosed = () => {
        setAlertClosed(alert.id, state.webToken, () => {
            setLoading(false)
            setError("");
            onClose();
            setRefresh(refresh + 1);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const markAsMuted = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e) {
            e.stopPropagation();
        }
        updateAlertMuted(alert.id, state.webToken, () => {
            setLoading(false)
            setError("");
            onClose();
            setRefresh(refresh + 1);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    React.useEffect(() => {
        setLoading(true);
        getAlertDetails(alert.id, state.webToken, (alertDetails: IAlertDetails) => {
            setLoading(false);
            setAlertDetails(alertDetails);
            setMessageId(alertDetails.alert.messageId);
            setTicket(alertDetails.alert.ticket);
            setError("");
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }, [alert.id, state.webToken, refresh]);

    const showUserAssignment = () => {
        setUserAssignment(true);
    };

    const closeUserAssignment = () => {
        setUserAssignment(false);
    };

    const assignUser = (user: IUser) => {
        alert.username = user.id;
        setRefresh(refresh + 1);
    };

    const changeMessageId = (event: SyntheticEvent<HTMLInputElement, Event>) => {
        setMessageId(event.currentTarget.value);
    };

    const updateMessageId = () => {
        setLoading(true);
        updateAlertMessageId(alert.id, messageId, state.webToken, (response: string) => {
            setLoading(false);
            setEditingMessageId(false);
            setError("");
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const handleKeyDownMessageId = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const key = e.key;
        if (key === "Enter") {
            updateMessageId();
        }
    };

    const allowEditingMessageId = () => {
        setEditingMessageId(true);
    };

    const changeTicket = (event: SyntheticEvent<HTMLInputElement, Event>) => {
        setTicket(event.currentTarget.value);
    };

    const updateTicket = () => {
        setLoading(true);
        updateAlertTicket(alert.id, ticket, state.webToken, (response: string) => {
            setLoading(false);
            setEditingTicket(false);
            setError("");
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const handleKeyDownTicket = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const key = e.key;
        if (key === "Enter") {
            updateTicket();
        }
    };

    const allowEditingTicket = () => {
        setEditingTicket(true);
    };

    const openSupportDialog = (alertIssue: string) => () => {
        setIssue(alertIssue);
        setShowSupportDialog(true);
    };

    const closeCreateIssueDialog = () => {
        setRefresh(refresh + 1);
        setLoading(false);
        setShowSupportDialog(false);
    };

    const onSaveSuccess = () => {
        navigate("/Ram/Support");
    };

    const onSaveIssue = (issue: ISupportIssueRequest) => {
        if (issue.issue) {
            setLoading(true);
            saveSupportIssue(issue, state.webToken, onSaveSuccess, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    };

    const closed = alertDetails && alertDetails.alert && alertDetails.alert.status === "Closed";

    return <OuterContainer>
        {showSupportDialog && <CreateSupportIssueDialog error={error} loading={loading} onClose={closeCreateIssueDialog} onSave={onSaveIssue} issue={issue} />}
        <AssignUserDialog assignUser={assignUserToAlert} show={userAssignment} onClose={closeUserAssignment} itemId={alert.id} onConfirmUserAdd={assignUser} />
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{createErrorMessage("loading RAM alerts", error)}</ErrorBox>}
        <ContentContainer>
            {alertDetails && alertDetails.instancesSameServer && <DetailContainer>
                <AlertContainer closed={closed}>
                    <AlertHeader closed={closed}>
                        <h3>Issue Details</h3>
                        <AlertButtons>
                            {!closed && alertDetails.editable && <Button onClick={markAsMuted}>{alertDetails.alert.muted ? "Muted" : "Mute"}</Button>}
                            {!closed && state.canRaiseSupport && <Button onClick={openSupportDialog(alertDetails.alert.alert)}>Raise support issue</Button>}
                            {!closed && alertDetails.editable && <Button onClick={showUserAssignment}>{alert.username ? "Reassign" : "Assign"}</Button>}
                            {!closed && alertDetails.editable && <Button onClick={markAsClosed}>Mark as closed</Button>}
                            <UsernameText backgroundColor={closed ? "#6d2f35" : (alert.userColour || '#818181')}>
                                {getUsername(alertDetails.alert, size !== "large", closed)}
                            </UsernameText>
                        </AlertButtons>
                    </AlertHeader>
                    <Container>
                        <AlertIcon type={alert.type} size="large" closed={closed} />
                        <AlertBody>
                            <h3>{alert.type}</h3>
                            <div className="grid">
                                <div className="grid-column-1">
                                    <dl>
                                        <dt>MessageID</dt>
                                        <dd>
                                            {editingMessageId ?
                                                <TextInput width='300px' autoFocus={true} maxLength={50} value={messageId} onChange={changeMessageId} onKeyDown={handleKeyDownMessageId} onBlur={updateMessageId} /> :
                                                messageId ?
                                                    <EditableTextContainer onClick={allowEditingMessageId}>{messageId}</EditableTextContainer> :
                                                    <AlertButton onClick={allowEditingMessageId}>Add message ID</AlertButton>
                                            }
                                        </dd>
                                        <dt>Ticket</dt>
                                        <dd>
                                            {editingTicket ?
                                                <TextInput width='300px' autoFocus={true} maxLength={50} value={ticket} onChange={changeTicket} onKeyDown={handleKeyDownTicket} onBlur={updateTicket} /> :
                                                ticket ?
                                                    <EditableTextContainer onClick={allowEditingTicket}>{ticket}</EditableTextContainer> :
                                                    <AlertButton onClick={allowEditingTicket}>Add ticket</AlertButton>
                                            }
                                        </dd>
                                        <dt>Age</dt><dd><Timeago date={alertDetails.alert.timestamp} formatter={ageFormatter} /></dd>
                                        <dt>Last reported</dt><dd><Timeago date={alertDetails.alert.lastUpdate} /></dd>
                                        <dt>Count</dt><dd>{alertDetails.alert.count}</dd>
                                        <dt>Alert</dt><dd>{alertDetails.alert.fullAlert ? alertDetails.alert.fullAlert : alertDetails.alert.alert}</dd>
                                    </dl>
                                </div>
                            </div>
                            <AlertNotes
                                addNote={addNote}
                                getNotes={getNotes}
                                itemId={alert.id}
                            />
                        </AlertBody>
                    </Container>
                </AlertContainer>
                <PreviousInstanceContainer closed={closed}>
                    <AlertHeader closed={closed}>
                        <h3>Previous instances of this issue</h3>
                    </AlertHeader>
                    <InstanceInformationRow>
                        <InstanceInformation>
                            <SectionTitle>This server</SectionTitle>
                            <dl>
                                <dt>First occurrence</dt><dd>{dateConverter(alertDetails.instancesSameServer.firstOccurrence, true)}</dd>
                                <dt>Last occurrence</dt><dd>{dateConverter(alertDetails.instancesSameServer.lastOccurrence, true)}</dd>
                                <dt>Previous occurrences</dt><dd>{alertDetails.instancesSameServer.previousOccurrences}</dd>
                            </dl>
                        </InstanceInformation>
                        <InstanceInformation>
                            <SectionTitle>Any server</SectionTitle>
                            <dl>
                                <dt>First occurrence</dt><dd>{dateConverter(alertDetails.instancesOtherServer.firstOccurrence, true)}</dd>
                                <dt>Last occurrence</dt><dd>{dateConverter(alertDetails.instancesOtherServer.lastOccurrence, true)}</dd>
                                <dt>Previous occurrences</dt><dd>{alertDetails.instancesOtherServer.previousOccurrences}</dd>
                                <dt>Last occurrence on</dt><dd>{alertDetails.instancesOtherServer.lastOccurrenceOn}</dd>
                            </dl>
                        </InstanceInformation>
                    </InstanceInformationRow>
                </PreviousInstanceContainer>
            </DetailContainer >
            }
            {alertDetails && alertDetails.history && <SideContainer>
                <AlertContainer closed={closed}>
                    <AlertHeader closed={closed}>
                        History
                    </AlertHeader>
                    <div>
                        <HistoryList size={size}>
                            {alertDetails.history.map(historyToItem)}
                            {alertDetails.closed && [
                                <dt key="alert-history-total-key">Total time</dt>,
                                <dd key="alert-history-total-value">{getDifference(alertDetails.closed, alertDetails.reported)}</dd>
                            ]}
                        </HistoryList>
                    </div>
                </AlertContainer>
                {props.showCurrentIssues && <CurrentIssues>
                    <h3>Current issues on this site</h3>
                    <SiteAlertsList site={alertDetails.alert.site} refresh={refresh} />
                </CurrentIssues>}
            </SideContainer>
            }
        </ContentContainer>
    </OuterContainer>;
};

export default AlertDetail;
