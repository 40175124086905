import IAlert from "src/ServerEntities/IAlert";
import IServerAlerts from "src/ServerEntities/IServerAlerts";

const sortAlertsByRecentness = (alertA: IAlert, alertB: IAlert) => {
  return Math.max(alertB.lastUpdate, alertB.timestamp) - Math.max(alertA.lastUpdate, alertA.timestamp)
};

const sortAlerts = (alertA: IAlert, alertB: IAlert) => {
  return Math.max(alertA.lastUpdate, alertA.timestamp) - Math.max(alertB.lastUpdate, alertB.timestamp)
};

export const sortByRecentness = (serverAlerts: IServerAlerts) => {
  serverAlerts.alerts.sort(sortAlertsByRecentness);
};

export const sortByOldness = (serverAlerts: IServerAlerts) => {
  serverAlerts.alerts.sort(sortAlerts);
};

export const filterAndSortUserAlerts = (servers: IServerAlerts[], loggedInUser: string): IServerAlerts[] => {
  const usersIssues = servers.map(server => {
    const filteredAlerts = server.alerts.filter(alert => alert.username === loggedInUser && alert.username !== null);
    return { ...server, alerts: filteredAlerts };
  }).filter(server => server.alerts.length > 0);

  usersIssues.forEach(sortByRecentness)

  return usersIssues;
};

export const filterAndSortUserAlertsBy = (servers: IServerAlerts[], loggedInUser: string, sortByRecent: boolean): IServerAlerts[] => {
  const usersIssues = servers.map(server => {
    const filteredAlerts = server.alerts.filter(alert => alert.username === loggedInUser && alert.username !== null);
    return { ...server, alerts: filteredAlerts };
  }).filter(server => server.alerts.length > 0);

  if (sortByRecent) {
    usersIssues.forEach(sortByRecentness);
  }
  else {
    usersIssues.forEach(sortByOldness);
  }

  return usersIssues;
};
