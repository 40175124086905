import axios, { AxiosError, AxiosResponse } from 'axios';

import { getAPIUrl } from 'src/config';
import IErrorDetails from 'src/ServerEntities/IErrorDetails';
import { NETWORK_ERROR } from 'src/UsefulFunctions/createErrorMessage';

const attemptLogin = (username: string, password: string, successCallback: (webToken: string) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}session`,
        {
            auth: {
                "password": password,
                "username": username
            }
        }
    )
    .then((response: AxiosResponse<string>) => {
        successCallback(response.data);
    })
    .catch((error: AxiosError<IErrorDetails>) => {
        let errorMessage = "Unknown error";
        if (error.response && error.response.status === 401) {
            errorMessage = "Incorrect username or password."
        } else if (error.response && error.response.status === 429) {
            errorMessage = "Timed out due to too many login attempts.";
        } else if (error.response) {
            errorMessage = error.response.data.message
        }
        if (error.message === "Network Error") {
            errorMessage = NETWORK_ERROR;
        }
        failureCallback(errorMessage as string);
    });
};

export const getUsernameForToken = (jwt: string, successCallback: (username: string) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(`${getAPIUrl()}session/${jwt}/username`)
    .then((response: AxiosResponse<string>) => {
        successCallback(response.data);
    })
    .catch((error: AxiosError<IErrorDetails>) => {
        let errorMessage = "Unknown error";
        if (error.response) {
            errorMessage = error.response.data.message;
        }
        if (error.message === "Network Error") {
            errorMessage = NETWORK_ERROR;
        }
        failureCallback(errorMessage as string);
    });
};

export const sendForgotPassword = (username: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(`${getAPIUrl()}password/forgot/${username}`)
        .then((response: AxiosResponse<string>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            let errorMessage = "Unknown error";
            if (error.response) {
                if (error.response.data.code === 404) {
                    errorMessage = "Email not found."
                } else {
                    errorMessage = error.response.data.message;
                }

            }
            if (error.message === "Network Error") {
                errorMessage = NETWORK_ERROR;
            }
            failureCallback(errorMessage as string);
        });
};

export const sendResetPassword = (password: string, token: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(`${getAPIUrl()}password/reset/${token}`, password)
        .then((response: AxiosResponse<string>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            let errorMessage = "Unknown error";
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            if (error.message === "Network Error") {
                errorMessage = NETWORK_ERROR;
            }
            failureCallback(errorMessage as string);
        });
};

export default attemptLogin;
