import React from 'react';
import 'react-toggle/style.css';
import styled from 'styled-components';

import IServer from 'src/ServerEntities/IServer';
import ISite from 'src/ServerEntities/ISite';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import createErrorMessage from 'src/UsefulFunctions/createErrorMessage';
import ServerItem from 'src/Views/Ram/ServerItem';

import { SessionContext } from 'src/Views/SessionContext';
import { getOverview } from './ramService';
import { renderSiteName } from 'src/UsefulFunctions/renderSiteName';
import ISitePermissions from 'src/ServerEntities/ISitePermissions';
import { getFirstPathOn401RoleChange } from 'src/Roles/RolesService';
import { useNavigate } from 'react-router';
import { RolesContext } from 'src/Roles/RolesContext';

const SitesTitle = styled.h2`
    font-weight: 600;
    font-size: 1.4rem;
    margin: 0;
`;

const SiteContainer = styled.ul`
    margin-top: 4px;
    @media (min-width: 1279px) {
        margin-top: 24px;
    }
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    & li {
        @media (max-width: 1279px) {
            display: none;
            width: 100%;
        }
        background-color: #42a83f;
        order: 3;
        max-width: 520px;
        & i {
            width: 30px;
        }
    }
    & li.with-alerts {
        background-color: #f99600;
        order: 2;
        @media (max-width: 1279px) {
            display: flex;
        }
    }
    & li.without-heartbeat {
        order: 1;
        font-size: 2rem;
        @media (max-width: 1279px) {
            display: flex;
        }
        & h3 {
            width: 300px;
            @media (max-width: 1279px) {
                width: 250px;
            }
        }
        max-width: 560px;
        & i {
            width: 70px;
            @media (max-width: 1279px) {
                display: none;
            }
        }
        background-color: #ff2121;
    }
`;

const ContentContainer = styled.main`
    display: flex;
    flex-direction: column;
    padding: 6px 0;
    flex: 1 0 auto;
    @media (min-width: 1279px) {
        padding: 48px 48px 84px;
    }
    @media (max-width: 1279px) {
        margin-bottom: 80px;
    }
`;

interface IProps {
    selectedTypes: string[],
    changeRole: boolean,
    setChangeRole: (changeRole: boolean) => void
};

const Overview = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { selectedTypes, changeRole, setChangeRole } = props;
    const [sites, setSites] = React.useState([] as unknown as ISite[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [refresh, setRefresh] = React.useState(0);
    const [expandedIds, setExpandedIds] = React.useState([] as unknown as string[]);
    const roleContext = React.useContext(RolesContext);
    const navigate = useNavigate();

    const getExpanded = (id: string) => {
        return !!(expandedIds.find(element => element === id));
    };

    const onExpand = (id: string) => {
        const index = expandedIds.indexOf(id);
        const arrCopy = [...expandedIds];
        if (index > -1) {
            arrCopy.splice(index, 1);
        } else {
            arrCopy.push(id);
        }
        setExpandedIds(arrCopy);
    };

    const serverToRow = (site: string, siteName: string, permissions: ISitePermissions) => (server: IServer, index: number) => {
        return <ServerItem sitePermissions={permissions} onExpand={onExpand} startExpanded={getExpanded(server.id)} enlarge={true} displaySite={true} linkToServer={true} site={site} siteName={siteName} server={server} key={`site-${siteName}-server-${server.name}-${index}`} refresh={refresh} setRefresh={setRefresh} />;
    };

    const siteToRow = (site: ISite, index: number) => {
        return site.servers.map(serverToRow(site.site, renderSiteName(site.site, site.alias), site.permissions));
    };

    const checkRoleChange = async (errorMessage: string) => {
        if(changeRole) {
            const path = await getFirstPathOn401RoleChange(errorMessage, state.webToken, roleContext.state.ramConfiguration?.components, roleContext.state.role)
            if(path) {
                navigate(path);
            }
            setChangeRole(false);
        } 
    }

    React.useEffect(() => {
        setLoading(true);
        setSites([]);
        getOverview(state.webToken, (serverSites: ISite[]) => {
            setLoading(false);
            setSites(serverSites);
            setError("");
            setChangeRole(false);
        }, (errorMessage: string) => {
            checkRoleChange(errorMessage);
            setLoading(false);
            setError(errorMessage);
        });
    }, [state.webToken, refresh]);

    const filteredSites = sites.filter(site => selectedTypes.length === 0 || selectedTypes.includes(site.type));

    return <ContentContainer>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{createErrorMessage("loading RAM sites", error)}</ErrorBox>}
        <SitesTitle>All sites</SitesTitle>
        <SiteContainer>
            {filteredSites.map(siteToRow)}
        </SiteContainer>
    </ContentContainer>;
};

export default Overview;
