import * as React from 'react';
import styled from 'styled-components';

import useFocus from 'src/CustomHooks/useFocus';

import Menu from 'src/SharedComponents/Menu/Menu';
import MenuItem from 'src/SharedComponents/Menu/MenuItem';
import HeaderLink from 'src/Views/Ram/Components/HeaderLink';

interface IUserMenuProps {
    role?: string,
    username: string,
    onSignOut: () => void,
    onChangeRole: () => void,
    onGotoManager: () => void
};

const Wrapper = styled.span`
    display: flex;
`;

const UserContainer = styled.div`
padding: 8px 16px 12px 16px;
border-bottom:1px solid #e5e5e5;
user-interaction:none;
cursor:default;
`;

const TopLabel = styled.p`
margin:0px;
font-size: 14px;
line-height:19px;
color: #555555;
`;

const UsernameLabel = styled.p`
margin:0px;
font-size: 16px;
font-weight:500;
line-height: 25px
color: #000000;
`;

const RoleLabel = styled.p`
margin:0px;
font-size: 14px;
line-height: 22px;
`;

const RamUserMenu = (props: IUserMenuProps) => {
    const { role, username, onSignOut, onChangeRole, onGotoManager } = props;
    const [navMenuOpen, setNavMenuOpen] = React.useState(false);
    const menuButtonRef = React.useRef(null as unknown as HTMLAnchorElement);
    const menuRef = React.useRef(null as unknown as HTMLDivElement);
    const focus = useFocus(menuRef);

    const toggleNavMenu = () => {
        if (!navMenuOpen) {
            setNavMenuOpen(true);
            focus();
        } else {
            setNavMenuOpen(false);
        }
    };

    const closeNavMenu = () => {
        setNavMenuOpen(false);
    };

    const handleKeyDown = (isFirst: boolean, isLast: boolean, enterFunction: () => void) => (event: any) => {
        const key = event.key || event.keyCode;
        if (key === "Enter" || key === " ") {
            enterFunction();
        }
        if (key === "Escape") {
            menuButtonRef.current.focus();
            setNavMenuOpen(false);
        }
        if (key === "Tab" && event.shiftKey && isFirst) {
            menuButtonRef.current.focus();
            setNavMenuOpen(false);
        }
        if (key === "Tab" && !event.shiftKey && isLast) {
            menuButtonRef.current.focus();
            setNavMenuOpen(false);
        }
    };

    return <Wrapper>
        <HeaderLink onClick={toggleNavMenu} ref={menuButtonRef}>
            {username}
            <Menu menuContainerRef={menuRef} open={navMenuOpen} onClose={closeNavMenu} anchorElement={menuButtonRef} key="userMenu" width={300}>
                <UserContainer>
                    <TopLabel>Signed in as</TopLabel>
                    <UsernameLabel>{username}</UsernameLabel>
                    <RoleLabel>{role}</RoleLabel>
                </UserContainer>
                <MenuItem key="change-role-item" onKeyDown={handleKeyDown(true, false, onChangeRole)} onClick={onChangeRole}>
                    Change role
                </MenuItem>
                <MenuItem key="manager-item" onKeyDown={handleKeyDown(true, false, onGotoManager)} onClick={onGotoManager}>
                    Manager
                </MenuItem>
                <MenuItem key="sign-out-item" onKeyDown={handleKeyDown(false, true, onSignOut)} onClick={onSignOut}>
                    Sign out
                </MenuItem>
            </Menu>
        </HeaderLink>
    </Wrapper>;
};

export default RamUserMenu;
