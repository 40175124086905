import styled from 'styled-components';

import ViewContainer from './ViewContainer';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 48px;
    margin-bottom: 128px;
    align-items: center;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction:row;
    font-size: 21px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: #575757;
    justify-content: center;
    margin-bottom: 24px;
`;

const ViperLogo = styled.img`
    width: 197px;
    margin-right: 8px;
`;

const TopBar = styled.div`
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 10px 16px;
`;

const FooterBar = styled.div`
    font-size: 14px;
    color: #555;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
`;

interface IProps {
    children?: any;
};

const ContentContainer = (props: IProps) => {
    return <ViewContainer style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <TopBar>
            &nbsp;
        </TopBar>
        <Container>
            <TitleContainer>
                <ViperLogo src="/images/viperLogoColoured.png" />
            </TitleContainer>
            {props.children}
        </Container>
        <FooterBar>&nbsp;</FooterBar>
    </ViewContainer>;
};

export default ContentContainer;
