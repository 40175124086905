import axios, { AxiosError, AxiosResponse } from "axios";

import IRoleAndJwt from 'src/Roles/IRoleAndJwt';
import { IManagerConfiguration, IRamConfiguration, RAM_COMPONENT_NAME } from "src/ServerEntities/IConfiguration";
import IErrorDetails from "src/ServerEntities/IErrorDetails";
import { NETWORK_ERROR } from 'src/UsefulFunctions/createErrorMessage';

import { getAPIUrl } from 'src/config';

export const changePreferredRole = (roleName: string, applicationName: string, webToken: string, successCallback: (jwt: string) => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}dashboard/preferredRole`,
        {
            app: applicationName,
            role: roleName
        },
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<string>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getRoles = (applicationName: string, webToken: string, successCallback: (data: any) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}dashboard/${applicationName}/roles`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<string>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getManagerConfigurationForRole = (roleName: string, webToken: string,
    successCallback: (configuration: IManagerConfiguration) => void, failureCallback: (errorMessage: string) => void) => {

    axios.get(
        `${getAPIUrl()}dashboard/configuration/Manager/${roleName}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IManagerConfiguration>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getRamConfigurationForRole = (roleName: string, webToken: string,
    successCallback: (configuration: IRamConfiguration) => void, failureCallback: (errorMessage: string) => void) => {

    axios.get(
        `${getAPIUrl()}dashboard/configuration/RAM/${roleName}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IRamConfiguration>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getRolePermissions = (roleName: string, webToken: string,
    successCallback: (roleMethods: any) => void, failureCallback: (errorMessage: string) => void) => {
        axios.get(
            `${getAPIUrl()}roles/${roleName}/permissions`,
            { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
        ).then((response: AxiosResponse<any>) => {
            successCallback(response.data);
        }).catch((error: AxiosError<IErrorDetails>) => {
            failureCallback(workoutErrorMessage(error));
        });
};

export const getPreferredRole = (applicationName: string, webToken: string, successCallback: (preferredRole: IRoleAndJwt) => void,
    failureCallback: (errorMessage: string, errorCode?: number) => void) => {

    axios.get(
        `${getAPIUrl()}dashboard/${applicationName}/preferredRole`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<{ jwt: string, role: string, username: string }>) => {
        const roleToUse = response.data.role;

        if (roleToUse !== undefined && roleToUse !== null) {
            successCallback({
                jwt: response.data.jwt,
                role: roleToUse,
                username: response.data.username
            });
        } else {
            if (response.data.role === "") {
                failureCallback("You have no role assigned to your user account. Please contact your IT administrator for assistance.")
            } else {
                failureCallback(`You have an unknown role "${response.data}" assigned to your user account. Please contact your IT administrator for assistance.`)
            }
        }
    }).catch((error: AxiosError<IErrorDetails>) => {
        failureCallback(workoutErrorMessage(error), error.response ? error.response.status : 404);
    });
};

const workoutErrorMessage = (error: AxiosError<IErrorDetails>) => {
    let errorMessage = "Unknown error";
    if (error.response) {
        if (error.response.status === 409) {
            errorMessage = "List already exists";
        } else {
            errorMessage = error.response.data.message
        }
    }
    if (error.message === "Network Error") {
        errorMessage = NETWORK_ERROR;
    }
    return errorMessage as string
};

export const getFirstPathOn401RoleChange = async (errorMessage: string, webToken: string, components?: RAM_COMPONENT_NAME[], role?: string): Promise<string | null> => {
    const httpStatusCode = errorMessage.match(/\d{3}/);
    if (httpStatusCode && httpStatusCode[0] === '401' && role && components) {
        try {
            const paths = await getRolePaths(role, webToken, components);
            const convertedPaths = paths as string[];
            if (convertedPaths.length > 0) {
                return convertedPaths[0];
            } else {
                return null;
            }
        } catch (error) {
            // Handle error if needed
            return null; // Ensure a value is returned in case of an error
        }
    } else {
        return null; // Return a default value if the condition is not met
    }
}


export const getRolePaths = (role: string, webToken: string, components: RAM_COMPONENT_NAME[]) => {
    return new Promise((resolve, reject) => {
        const paths: string[] = [];
        getRolePermissions(role, webToken, (roleMethods: any[]) => {                        
            const dashboard = roleMethods.find((method) => method.method === '/ram/sites') ? "Dashboard" : null;
            const roleDashboard = roleMethods.find((method) => method.method === '/client/dashboard') ? "Dashboard" : null;
            const hybrid = roleMethods.find((method) => method.method === '/ram/hybrid') ? "Hybrid" : null;
            const overview = roleMethods.find((method) => method.method === '/ram/sites') ? "Overview" : null;
            const alerts = roleMethods.find((method) => method.method === '/ram/alerts/current') ? "Alerts" : null;
            const sites = roleMethods.find((method) => method.method === '/ram/hybrid') ? "Sites" : null;
            const clients = roleMethods.find((method) => method.method === '/client/{client}/dashboard') ? "Clients" : null;
            const support = roleMethods.find((method) => method.method === '/ram/issues') ? "Support" : null;

            components.forEach((component) => {
                switch (component) {
                    case dashboard:
                        paths.push("/RAM/Dashboard");
                        break;
                    case roleDashboard:
                        paths.push("/RAM/RoleDashboard");
                        break;
                    case overview:
                        paths.push("/RAM/Overview");
                        break;
                    case hybrid:
                        paths.push("/RAM/Hybrid");
                        break;
                    case alerts:
                        paths.push("/RAM/Alerts");
                        break;
                    case sites:
                        paths.push("/RAM/Sites");
                        break;
                    case clients:
                        paths.push("/RAM/Clients");
                        break;
                    case support:
                        paths.push("/RAM/Support");
                        break;
                }
            });

            resolve(paths); 
        }, (error: string) => {
            reject(error); 
        });
    });
};

